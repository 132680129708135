import { navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import AppPageContainer from '../AppPageContainer';
import ToolBar from '../ToolBar';
import { Keys } from '../../modules/keys_module';
import { createEmissionQuote, Emissions, fetchEmissions, getOneEmission } from '../../modules/emissions_module';
import { Box, Input, Button, Stat, StatGroup, StatHelpText, StatLabel, StatNumber, Tag, InputGroup, InputRightAddon, Slider, SliderTrack, SliderFilledTrack, SliderThumb, NumberInput, NumberInputField, HStack, useShortcut } from '@chakra-ui/react';
import { confirmQuoute, Quotes } from '../../modules/offsets_module';
import TagManagerModal from '../tag-manager-modal';


const EmissionsTemplate = ({id}) => {
    const emissions = Emissions.use();
    const quotes = Quotes.use();
    const keys = Keys.use();

    const [loading, setLoading] = useState({});
    const [coverage, setCoverage] = useState(1);
 
    useEffect(() => {
        getOneEmission(keys.activeKey, id)
    }, [keys.activeKey, keys.activeEnv])

    const purchase = async (uid) => {
        setLoading({ ...loading, [uid]: true });
        const result = await confirmQuoute(keys.activeKey, uid);
        getOneEmission(keys.activeKey, id)
        setLoading({ ...loading, [uid]: false });
    }

    const format = (val) => val + "%"

    const createQuote = async () => {
        await createEmissionQuote(keys.activeKey, {
            [id]: coverage
        })
        getOneEmission(keys.activeKey, id)
    }

    const [tagModal, setTagModal] = useState(false);

    return (
        <AppPageContainer activePage="emissions">
            <ToolBar pageName="Your Emission" />
            { (emissions?.additionalInfo && emissions?.error) && (
                <div className="mt-4 p-3 border-error border rounded-lg bg-error bg-opacity-20">
                    Your currently selected API environment does not have an active API key.
                </div>
            )}
            <TagManagerModal 
                object={emissions.view} 
                objectId={id} 
                objectType="Emission" 
                open={tagModal}
                onClose={() => {setTagModal(false); getOneEmission(keys.activeKey, id); }}
            />
            { emissions.view && (
            <div className="mt-4 space-y-4">
                <div className="text-xl font-bold">ID: {id}</div>
                <Box borderWidth="1pt" p={4}>
                    <HStack mb={2}>
                        {emissions.view.Tags.map(tag => (
                            <Tag>{tag.DataTag.Name}</Tag>
                        ))}
                        <Button onClick={() => setTagModal(true)} size="xs">Edit Tags</Button>
                    </HStack>
                    <StatGroup>
                        <Stat>
                            <StatLabel>Carbon Emitted</StatLabel>
                            <StatNumber>{emissions.view.CarbonImpact}</StatNumber>
                            <StatHelpText>kilograms</StatHelpText>
                        </Stat>
                        <Stat>
                            <StatLabel>Carbon Offset</StatLabel>
                            <StatNumber>{emissions.view.EmissionToQuotes.filter(e2q => e2q.Quote.Status.toLowerCase() === "purchased").reduce((p, c) => p + c.Quote.carbon_impact, 0)}</StatNumber>
                            <StatHelpText>kilograms</StatHelpText>
                        </Stat>
                        <Stat>
                            <StatLabel>Carbon Quotes</StatLabel>
                            <StatNumber>{emissions.view.EmissionToQuotes.filter(e2q => e2q.Quote.Status.toLowerCase() !== "purchased").reduce((p, c) => p + c.Quote.carbon_impact, 0)}</StatNumber>
                            <StatHelpText>kilograms</StatHelpText>
                        </Stat>
                    </StatGroup>
                </Box>
                <Box borderWidth="1pt" p={4}>
                    <div className="font-bold text-lg">Offsets for Emission</div>
                    <Box className="px-4 mt-2 text-sm grid grid-cols-5">
                        <Box>Status</Box>
                        <Box>Coverage</Box>
                        <Box>Carbon Impact</Box>
                        <Box>Cost</Box>
                    </Box>
                    {emissions.view.EmissionToQuotes.length == 0 && (
                        <Box className="p-4">No Quotes or Offsets</Box>
                    )}
                    {emissions.view.EmissionToQuotes.map(e2q => (
                        <Box className="grid grid-cols-5 items-center" borderBottomWidth="1pt" p={4}>
                            <Box>
                                <Tag>{e2q.Quote.Status}</Tag>
                            </Box>
                            <Box>{e2q.CoveragePercent}%</Box>
                            <Box>{e2q.Quote.carbon_impact}kg</Box>
                            <Box>${e2q.Quote.Cost}</Box>
                            <Box>{e2q.Quote.Status.toLowerCase() != "purchased" && (<Button onClick={() => purchase(e2q.Quote.Uid)}>Purchase</Button>)}</Box>
                        </Box>
                    ))}
                </Box>
                <Box borderWidth="1pt" p={4}>
                    <div className="font-bold text-lg">Offset Emissions</div>
                    <div>Coverage Percent. How much of this emission do you want to quote?</div>
                    <div className="my-2 flex space-x-2">
                        <NumberInput min={1} max={100} onChange={(e) => setCoverage(parseInt(e.target.value))} value={format(coverage)}>
                            <NumberInputField placeholder="Coverage Percent" />
                        </NumberInput>
                        <Slider value={coverage} focusThumbOnChange={false} onChange={val => setCoverage(val)} min={1} max={100}>
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                    </div>
                    <Button onClick={createQuote}>Create Quote</Button>
                </Box>
            </div>
            )}
           
        </AppPageContainer>
    );
};

export default EmissionsTemplate;
