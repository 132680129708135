import React, { useState } from 'react';

const BasicModal = ({isOpen, close, title, children}) => {

    return (
        <>
            {isOpen && (
                <div onClick={close} className="fixed z-50 top-0 left-0 w-full h-full bg-opacity-60 bg-gray-900 flex justify-center items-center">
                    <div onClick={(e) => {e.preventDefault(); e.stopPropagation();}} className="w-full h-full m-2 lg:h-auto lg:w-1/2 bg-white rounded-lg p-5 flex flex-col">
                        <div className="flex items-center border-b border-gray-400 pb-1">
                            <div className="flex-grow text-lg font-bold">{title}</div>
                            <div className="" onClick={close}>
                                <svg className="w-8 h-8 text-primary cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                        </div>
                        <div className="flex-grow pt-4">
                            {children}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BasicModal;
