import React, { useEffect, useState } from 'react';
import BasicModal from './BasicModal';
import TagManager from './tag-manager';

const TagManagerModal = ({objectType, object, objectId, open, onClose}) => {
    console.log(object)
    return (
        <BasicModal title={`Tags ${objectId}`} close={onClose} isOpen={open}>
            <TagManager objectType={objectType} objectId={objectId} taggables={object?.Tags} />
        </BasicModal>
    )
}

export default TagManagerModal;
