import axios from 'axios';
import {entity} from 'simpler-state';

const api = axios.create({
    baseURL: process.env.GATSBY_API_URL,
    timeout: 2000,
});

export const tagItem = async ({object_type, object_uid, tag_uid}, apiKey) => {
    const data = {object_type, object_uid, tag_uid}
    const result = await api.post(`tags/taggable`, data, {withCredentials: true, headers: { 'x-api-key': apiKey }});
    if (result.status == 200) {
        return result.data;
    } else {
        return null;
    }
}

export const untagItem = async ({object_type, object_uid, tag_uid}, apiKey) => {
    const data = {object_type, object_uid, tag_uid}
    const axioscofig = {
        data,
        withCredentials: true, headers: { 'x-api-key': apiKey }
    }
    const result = await api.delete(`tags/taggable`, axioscofig);
    if (result.status == 200) {
        return result.data;
    } else {
        return null;
    }
}

export const getAllTags = async (apiKey) => {
    const result = await api.get(`tags/tag`, {withCredentials: true, headers: { 'x-api-key': apiKey }});
    if (result.status == 200) {
        return result.data;
    } else {
        return null;
    }
}

export const createTag = async ({name, description}, apiKey) => {
    const data = {name, description}
    const result = await api.post(`tags/tag`, data, {withCredentials: true, headers: { 'x-api-key': apiKey }});
    if (result.status == 200) {
        return result.data
    } else {
        return null
    }
}

export const deleteTag = async (tagId, apiKey) => {
    const result = await api.delete(`tags/tag/${tagId}`, {}, {withCredentials: true, headers: { 'x-api-key': apiKey }});
    if (result.status == 200) {
        return result.data
    } else {
        return null
    }
}