import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Button, Tag, TagLabel, Input, TagRightIcon, FormControl, FormLabel, HStack } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Keys } from '../modules/keys_module';
import { createTag, getAllTags, tagItem, untagItem } from '../modules/tags_module';

const TagManager = ({taggables, objectType, objectId}) => {
    const keys = Keys.use();
    const [tags, setTags] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    

    useEffect(() => {
        getAllTags(keys.activeKey).then((data) => {
            setTags(data)
        })
    }, [keys.activeKey, keys.activeEnv])

    useEffect(() => {
        setSelectedTags(taggables?.map(taggable => taggable?.DataTag?.Uid))
    }, [taggables])

    const toggleTag = async (tag_uid, object_type, object_uid, toggled) => {
        if (!toggled) {
            let tag = await tagItem({object_type, object_uid, tag_uid}, keys.activeKey)
            setSelectedTags(selectedTags.concat(tag.Uid))
        } else {
            let tag = await untagItem({object_type, object_uid, tag_uid}, keys.activeKey)
            setSelectedTags(selectedTags.filter(t => t !== tag_uid))
        }
    }

    return (
        <div>
            <div className="space-y-2">
            {tags?.map(tag => (
                <div className="grid grid-cols-3 items-center">
                    <div>
                        <Tag onClick={() => toggleTag(tag.Uid, objectType, objectId, selectedTags?.includes(tag.Uid))} cursor="pointer" colorScheme={(selectedTags?.includes(tag.Uid)) ? "blue" : "gray"} title={tag.Description}>
                            <TagLabel>{tag.Name}</TagLabel>
                            {(selectedTags?.includes(tag.Uid)) ? (
                                <TagRightIcon as={MinusIcon} />
                            ) : (
                                <TagRightIcon as={AddIcon} />
                            )}  
                        </Tag>
                    </div>
                    <div>
                        {tag.Description}
                    </div>
                    <div className="p-1 bg-purple-200 rounded">
                        <code className="text-purple-900">{tag.Uid}</code>
                    </div>
                </div>
            ))}
            </div>
            <div className="mt-2">
                <Formik
                    initialValues={{tagName: "", description:""}}
                    onSubmit={async (values) => {
                        console.log("Saving")
                        let tag = await createTag({name: values.tagName, description: values.description}, keys.activeKey)
                        toggleTag(tag.Uid, objectType, objectId, false)
                        setTags(tags.concat(tag))
                        setSelectedTags(selectedTags.concat(tag.Uid))
                    }}
                >
                    {(props) => (
                        <Form>
                            <HStack align="flex-end">
                                <Field name="tagName">
                                    {({ field }) => (  
                                        <FormControl>
                                            <FormLabel htmlFor="tagName">Tag Name</FormLabel>
                                            <Input {...field}  id="tagName"/>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name="description">
                                    {({field, form}) => (
                                        <FormControl>
                                            <FormLabel>Description</FormLabel>
                                            <Input {...field} id="description" />
                                        </FormControl>
                                    )}
                                </Field>
                                <Button onClick={props.submitForm} as="button" disabled={props.isSubmitting} type="submit">
                                    Add
                                </Button>
                            </HStack>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default TagManager;
